import React from 'react';
import { useState,useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { YellowBtn } from 'src/components/layouts/base/btns/btns';
import { Container } from 'src/components/layouts/components';
import { PAGES_IDS } from 'src/config/constants';
import { footer_content, footer_btns, footer } from 'src/styles/Common.module.scss';
import { createPath } from 'src/utils/pathManager';
import CopyRight from './components/CopyRight';
import Logos from './components/Logos';
import NavMenu from './components/NavMenu'

const Footer = React.memo(({ pages, lang }) => {
    
    const [OFFER, setOFFER] = useState({
    description:"",
    meta_description:"",
    meta_keywords:"",
    meta_title:"",
    slug:{en:"offer",fr:"offer"},
    title:""
});
const [TRAINING, setTRAINING]  = useState({
    description:"",
    meta_description:"",
    meta_keywords:"",
    meta_title:"",
    slug:{en:"training",fr:"training"},
    title:""
})
const offer_title_slug = {en:'Offers',fr:'Nos offres'}
const training_title_slug = {en:'Training',fr:'Formations'}
useEffect(()=>{
    setOFFER({...OFFER,title:offer_title_slug[lang]})
    setTRAINING({...TRAINING,title:training_title_slug[lang]})
},[lang]);
const { CONTACT_US } = pages;
let develop_pages = {...pages,OFFER,TRAINING}
delete develop_pages.CONTACT_US
develop_pages.CONTACT_US = CONTACT_US;
if(develop_pages.CONTACT_US===undefined) delete develop_pages.CONTACT_US
    return (
        <footer className={footer}>
            <Container>
                <div className={footer_content}>
                    <Logos lang={lang} />
                    <NavMenu pages={develop_pages} lang={lang} />
                    <div className={footer_btns}>
                        {pages[PAGES_IDS.LEGAL_NOTICE] && (
                            <Link to={createPath([lang, pages[PAGES_IDS.LEGAL_NOTICE].slug[lang]])}>
                                <YellowBtn width='100%'>{pages[PAGES_IDS.LEGAL_NOTICE].title}</YellowBtn>
                            </Link>
                        )}
                        {pages[PAGES_IDS.PRIVACY_POLICY] && (
                            <Link to={createPath([lang, pages[PAGES_IDS.PRIVACY_POLICY].slug[lang]])}>
                                <YellowBtn width='100%'>{pages[PAGES_IDS.PRIVACY_POLICY].title}</YellowBtn>
                            </Link>
                        )}
                    </div>
                </div>
            </Container>
            <CopyRight />
        </footer>
    );
});

export default connect(({ main: { pages }, lang: { lang } }) => ({
    pages,
    lang,
}))(Footer);
