/** @module reducers/account/actions */

import axios from 'axios';
import { createAsyncAction } from 'redux-promise-middleware-actions';
import { ACTION_TYPES, API, LOCAL_STORAGE } from 'src/config/constants';
import AxiosTokenSource from 'src/reducers/AxiosTokenSource';
import { createActionReset } from 'src/reducers/base';

/**
 * ACTION login
 * @function
 */
export const login = createAsyncAction(ACTION_TYPES.LOGIN, (data) =>
    axios({ ...API.LOGIN, data, cancelToken: AxiosTokenSource.token }),
);

/**
 * ACTION update google credentials
 * @function
 */
export const update_google_credentials = createAsyncAction(ACTION_TYPES.LOGIN, (data) =>
    axios({ ...API.LOGIN, data, cancelToken: AxiosTokenSource.token }),
);

/**
 * ACTION refresh token
 * @function
 */
export const refresh_token = createAsyncAction(ACTION_TYPES.REFRESH_TOKEN, () =>
    axios({
        ...API.REFRESH_TOKEN,
        data: {
            refresh: localStorage.getItem(LOCAL_STORAGE.TOKEN_REFRESH),
        },
        cancelToken: AxiosTokenSource.token,
    }),
);

/**
 * ACTION get account data if already loged
 * @function
 */
export const get_account_data = createAsyncAction(ACTION_TYPES.GET_ACCOUNT_DATA, () =>
    axios({ ...API.GET_ACCOUNT_DATA, cancelToken: AxiosTokenSource.token }),
);

/**
 * ACTION reset account
 * @function
 */
export const reset_account = createActionReset(ACTION_TYPES.GET_ACCOUNT_DATA);

/**
 * ACTION logout
 * @function
 */
export const logout = createAsyncAction(ACTION_TYPES.LOGOUT, () =>
    axios({
        ...API.LOGOUT,
        data: {
            refresh: localStorage.getItem(LOCAL_STORAGE.TOKEN_REFRESH),
        },
    }),
);

/**
 * ACTION get accounts
 * @function
 */
export const get_accounts = createAsyncAction(ACTION_TYPES.GET_ACCOUNTS, () =>
    axios({ ...API.GET_ACCOUNTS, cancelToken: AxiosTokenSource.token }),
);

/**
 * ACTION post check account
 * @function
 */
export const post_check_account = createAsyncAction(ACTION_TYPES.POST_CHECK_ACCOUNT, ({ id, name }) =>
    axios({ ...API.POST_CHECK_ACCOUNT({ id }), data: { name }, cancelToken: AxiosTokenSource.token }),
);

/**
 * ACTION change status monthly report
 * @function
 */
export const post_status_monthly_report = createAsyncAction(
    ACTION_TYPES.POST_STATUS_MONTHLY_REPORT,
    ({ is_active_monthly_report }) =>
        axios({
            ...API.POST_STATUS_MONTHLY_REPORT,
            data: { is_active_monthly_report },
            cancelToken: AxiosTokenSource.token,
        }),
    ({ account_id }) => ({ account_id }),
);

/**
 * ACTION reset accounts
 * @function
 */
export const reset_accounts = createActionReset(ACTION_TYPES.GET_ACCOUNTS);

/**
 * ACTION get messages
 * @function
 */
export const get_messages = createAsyncAction(ACTION_TYPES.GET_MESSAGES, () =>
    axios({ ...API.GET_MESSAGES, cancelToken: AxiosTokenSource.token }),
);

/**
 * ACTION reset messages
 * @function
 */
export const reset_mesages = createActionReset(ACTION_TYPES.GET_MESSAGES);

/**
 * ACTION get subscription data
 * @function
 */
export const get_subscription_data = createAsyncAction(ACTION_TYPES.GET_SUBSCRIPTION_DATA, () =>
    axios({ ...API.GET_SUBSCRIPTION_DATA, cancelToken: AxiosTokenSource.token }),
);

/**
 * ACTION reset subscription
 * @function
 */
export const reset_subscription = createActionReset(ACTION_TYPES.GET_SUBSCRIPTION_DATA);

/**
 * ACTION get reports
 * @function
 */
export const get_reports = createAsyncAction(ACTION_TYPES.GET_REPORTS, () =>
    axios({ ...API.GET_REPORTS, cancelToken: AxiosTokenSource.token }),
);

/**
 * ACTION reset reports
 * @function
 */
export const reset_reports = createActionReset(ACTION_TYPES.GET_REPORTS);

/**
 * ACTION get report by id
 * @function
 */
export const get_report_by_id = createAsyncAction(ACTION_TYPES.GET_REPORT_BY_ID, ({ id }) =>
    axios({ ...API.GET_REPORT({ id }), cancelToken: AxiosTokenSource.token }),
);

/**
 * ACTION delete report by id
 * @function
 */
export const delete_report_by_id = createAsyncAction(ACTION_TYPES.DELETE_REPORT_BY_ID, ({ id }) =>
    axios({ ...API.DELETE_REPORT({ id }), cancelToken: AxiosTokenSource.token }),
);

/**
 * ACTION get report's pages
 * @function
 */
export const get_report_pages = createAsyncAction(ACTION_TYPES.GET_REPORT_PAGES, ({ id }) =>
    axios({ ...API.GET_REPORT_PAGES({ id }), cancelToken: AxiosTokenSource.token }),
);

/**
 * ACTION get report's pages in background
 * @function
 */
export const get_report_pages_in_background = createAsyncAction(ACTION_TYPES.GET_REPORT_PAGES_BACK, ({ id }) =>
    axios({ ...API.GET_REPORT_PAGES({ id }), cancelToken: AxiosTokenSource.token }),
);

/**
 * ACTION reset reports pages
 * @function
 */
export const reset_reports_pages = createActionReset(ACTION_TYPES.GET_REPORT_PAGES);

/**
 * ACTION create report
 * @function
 */
export const create_report = createAsyncAction(ACTION_TYPES.POST_REPORT, (data) =>
    axios({ ...API.POST_REPORT, data, cancelToken: AxiosTokenSource.token }),
);

/**
 * ACTION reset creating report
 * @function
 */
export const reset_create_report = createActionReset(ACTION_TYPES.POST_REPORT);

/**
 * ACTION update report form
 * @function
 */
export const update_report_form = createAsyncAction(ACTION_TYPES.POST_REPORT_FORM, (data) =>
    axios({ ...API.POST_REPORT_FORM, data, cancelToken: AxiosTokenSource.token }),
);

/**
 * ACTION reset updating report form
 * @function
 */
export const reset_update_report_form = createActionReset(ACTION_TYPES.POST_REPORT_FORM);

/**
 * ACTION update report template
 * @function
 */
export const get_report_page_template = createAsyncAction(
    ACTION_TYPES.GET_REPORT_PAGE_TEMPLATE,
    async ({ id, slug, format = "" }) =>
        await axios({
            ...API.GET_REPORT_PAGE_TEMPLATE({ id, slug, format }),
            cancelToken: AxiosTokenSource.token,
        }),
);

/**
 * ACTION reset report template
 * @function
 */
export const reset_report_page_template = createActionReset(ACTION_TYPES.GET_REPORT_PAGE_TEMPLATE);

/**
 * ACTION send report to email
 * @function
 */
export const report_email = createAsyncAction(ACTION_TYPES.POST_REPORT_EMAIL, ({ id, email }) =>
    axios({ ...API.POST_REPORT_EMAIL({ id }), data: { email }, cancelToken: AxiosTokenSource.token }),
);

/**
 * ACTION download report as pdf
 * @function
 */
export const get_report_pdf = createAsyncAction(
    ACTION_TYPES.GET_REPORT_PDF,
    ({ id }) => axios({ ...API.GET_REPORT_PDF({ id }), cancelToken: AxiosTokenSource.token }),
    ({ id, file_name, lang }) => ({ id, file_name, lang }),
);

/**
 * ACTION download report as pdf
 * @function
 */
export const reset_report_pdf = createActionReset(ACTION_TYPES.GET_REPORT_PDF);

/**
 * ACTION of adding mark to report page
 * @function
 */
export const add_report_page_mark = createAsyncAction(
    ACTION_TYPES.POST_REPORT_PAGE_MARK,
    ({ id, slug, ...data }) =>
        axios({ ...API.POST_REPORT_PAGE_MARK({ id, slug }), data, cancelToken: AxiosTokenSource.token }),
    ({ id, slug }) => ({ id, slug }),
);

/**
 * ACTION of grant google mcc access
 * @function
 */
export const grant_google_mcc_access = createAsyncAction(
    ACTION_TYPES.GRANT_GOOGLE_MCC_ACCESS,
    ({ reports_group_id, ...data }) =>
        axios({ ...API.GRANT_GOOGLE_MCC_ACCESS({ reports_group_id }), data, cancelToken: AxiosTokenSource.token }),
    ({ reports_group_id }) => ({ reports_group_id }),
);
