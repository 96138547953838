/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import ArrowImgSrc from 'src/images/arrow.png';
import ErrorImgSrc from 'src/images/icons/alert_error.svg';
import RemoveImgSrc from 'src/images/icons/ball.svg';
import MailImgSrc from 'src/images/icons/mail2.svg';
import MarkBadImgSrc from 'src/images/icons/mark_bad.png';
import MarkGoodImgSrc from 'src/images/icons/mark_good.png';
import MarkNormalImgSrc from 'src/images/icons/mark_normal.png';
import PhoneImgSrc from 'src/images/icons/phone.svg';
import PointImgSrc from 'src/images/icons/point.svg';
import SearchImgSrc from 'src/images/icons/search-icon.svg';
import SendImgSrc from 'src/images/icons/send.png';
import TimeImgSrc from 'src/images/icons/time.svg';
import NavLogoImgSrc from 'src/images/logos/adwookie-conseil.png';
import ModalWindowLogoImgSrc from 'src/images/logos/modal_window_logo.png';
import WookieChatImgSrc from 'src/images/wookie_chat.png';

export const RemoveImg = ({ ...args }) => <img src={RemoveImgSrc} alt='remove' loading='lazy' {...args} />;
export const ModalWindowLogoImg = ({ ...args }) => (
    <img src={ModalWindowLogoImgSrc} alt='modal_window_logo' loading='lazy' {...args} />
);
export const MailImg = ({ ...args }) => (
    <img src={MailImgSrc} alt='mail' loading='lazy' width={19} height={15} {...args} />
);
export const SearchImg = ({ ...args }) => <img src={SearchImgSrc} alt='search' loading='lazy' {...args} />;
export const NavLogoImg = ({ ...args }) => (
    <img src={NavLogoImgSrc} alt='search' loading='lazy' width={160} height={155} {...args} />
);
export const PhoneImg = ({ ...args }) => (
    <img src={PhoneImgSrc} alt='phone' loading='lazy' width={19} height={15} {...args} />
);
export const PointImg = ({ ...args }) => (
    <img src={PointImgSrc} alt='point' loading='lazy' width={19} height={23} {...args} />
);
export const ArrowImg = ({ ...args }) => <img src={ArrowImgSrc} alt='arrow' loading='lazy' {...args} />;
export const WookieChatImg = ({ ...args }) => <img src={WookieChatImgSrc} alt='wookie' loading='lazy' {...args} />;
export const SendImg = ({ ...args }) => <img src={SendImgSrc} alt='send' loading='lazy' {...args} />;
export const MarkBadImg = ({ ...args }) => <img src={MarkBadImgSrc} alt='mark_bad' loading='lazy' {...args} />;
export const MarkGoodImg = ({ ...args }) => <img src={MarkGoodImgSrc} alt='mark_good' loading='lazy' {...args} />;
export const MarkNormalImg = ({ ...args }) => <img src={MarkNormalImgSrc} alt='mark_normal' loading='lazy' {...args} />;
export const TimeImg = ({ ...args }) => <img src={TimeImgSrc} alt='monthly reports' loading='lazy' {...args} />;
export const ErrorImg = ({ ...args }) => <img src={ErrorImgSrc} alt='error' loading='lazy' {...args} />;
