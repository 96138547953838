import React, { useEffect } from 'react';
import { useState } from 'react';

import { connect } from 'react-redux';
import { APPS_PATHS } from 'src/config/constants';
import { history } from 'src/config/store'
import { ContainerHead } from 'src/components/layouts/components';
import { logOut } from 'src/reducers/account/auth';
import { createPath } from 'src/utils/pathManager';
import { resetTimeout } from 'src/utils/autoLogOut';
import { header_right_section, header_content } from 'src/styles/Common.module.scss';

import AccoutPanel from './components/AccoutPanel';
import LanguagesPanel from './components/LanguagesPanel';
import Logo from './components/Logo';
import NavMenu from './components/NavMenu';


const Header = React.memo(({ pages, is_fetched, lang, auth, logOut }) => {

    const redirectOnLogOut = (callback, lang) => () => {
        console.log(callback)
        callback();
        history.push(createPath([lang, APPS_PATHS.ACCOUNT[lang]]));
    };
    //TO REMOVE BEFORE DEPLOY HERE AND IN LENDING
    const [OFFER, setOFFER] = useState({
        description:"",
        meta_description:"",
        meta_keywords:"",
        meta_title:"",
        slug:{en:"offer",fr:"offer"},
        title:""
    });
    const [TRAINING, setTRAINING]  = useState({
        description:"",
        meta_description:"",
        meta_keywords:"",
        meta_title:"",
        slug:{en:"training",fr:"training"},
        title:""
    })
    const offer_title_slug = {en:'Offers',fr:'Nos offres'}
    const training_title_slug = {en:'Training',fr:'Formations'}

    useEffect(() => {
        if (auth.is_signed_in) {
            window.onload = resetTimeout(redirectOnLogOut(logOut, lang));
            window.addEventListener("mouseover", () => resetTimeout(redirectOnLogOut(logOut, lang)));
            window.addEventListener("keypress", () => resetTimeout(redirectOnLogOut(logOut, lang)));
        }
    }, [auth])

    useEffect(()=>{
        setOFFER({...OFFER,title:offer_title_slug[lang]})
        setTRAINING({...TRAINING,title:training_title_slug[lang]})
    },[lang]);
    const { CONTACT_US } = pages;
    let develop_pages = {...pages,OFFER,TRAINING}
    delete develop_pages.CONTACT_US
    develop_pages.CONTACT_US = CONTACT_US;
    if(develop_pages.CONTACT_US===undefined) delete develop_pages.CONTACT_US
    console.log("test here pages",develop_pages)
    return (
        <header>
            <ContainerHead>
                <div className={header_content}>
                    <Logo lang={lang} />
                    <div className={header_right_section}>
                        <NavMenu pages={develop_pages} lang={lang} />
                        <LanguagesPanel pages={pages} lang={lang} />
                        <AccoutPanel lang={lang} auth={auth} logOut={logOut} />
                    </div>
                </div>
            </ContainerHead>
        </header>
    );
});

export default connect(
    ({ main: { pages }, lang: { lang }, auth }) => ({
        pages,
        lang,
        auth,
    }),
    { logOut },
)(Header);
