/** @module reducers/account/auth */

import axios from 'axios';
import { REDUCERS_NAMES, LOCAL_STORAGE, HEADERS } from 'src/config/constants';
import {
    login,
    logout,
    get_account_data,
    reset_account,
    update_google_credentials,
} from 'src/reducers/account/actions';
import {
    resetMessages,
    resetAccounts,
    resetReports,
    resetReportsPages,
    resetSubscriptionData,
    resetCreateReport,
    resetReportPageTemplate,
    resetReportPDF,
    // getMessages,
    resetUpdateReportForm,
} from 'src/reducers/account/reducers';
import AxiosTokenSource from 'src/reducers/AxiosTokenSource';
import {
    baseInitialStateDataObject,
    baseReducer,
    actionHandlerOnce,
    actionHandlerReset,
    actionHandler,
    rejectedState,
    fulfilledState,
    pendingState,
} from 'src/reducers/base';
import { create_feedback } from 'src/reducers/lending/actions';

const resetLocalCredentials = () => {
    localStorage.removeItem(LOCAL_STORAGE.TOKEN_ACCESS);
    localStorage.removeItem(LOCAL_STORAGE.TOKEN_REFRESH);
    localStorage.removeItem(LOCAL_STORAGE.SELECTED_ACCOUNT_ID);
    localStorage.removeItem(LOCAL_STORAGE.SELECTED_ACCOUNT_NAME);
    delete axios.defaults.headers.common[HEADERS.AUTHORIZATION];
    delete axios.defaults.headers.common[HEADERS.ACCOUNT_ID];
    console.log('resetLocalCredentials', axios.defaults.headers.common);
};

/**
 * AUTH INITIAL STATE
 * @constant
 * @type {object}
 * @default
 */
export const authInitialState = {
    ...baseInitialStateDataObject,
    is_signed_in: false,
    is_logouting_right_now: false,
};

/**
 * LOGIN ACTION HANDLER
 * @type {function}
 */
export const logIn = actionHandlerOnce(REDUCERS_NAMES.ACCOUNT, login);

/**
 * update_google_credentials ACTION HANDLER
 * @type {function}
 */
export const updateGoogleCredentials = actionHandler(update_google_credentials, true);

/**
 * LOGIN if token exists ACTION HANDLER
 * @type {function}
 */
export const getAccountData = actionHandlerOnce(REDUCERS_NAMES.ACCOUNT, get_account_data, true);

/**
 * RESET ACCOUNT ACTION HANDLER
 * @type {function}
 */
export const resetAccount = actionHandlerReset(reset_account, authInitialState);

/**
 * LOGOUT ACTION HANDLER
 * @type {function}
 */
export const logOut = () => async (dispatch) => {
    AxiosTokenSource.cancel();
    await dispatch(logout());
    await Promise.all([
        dispatch(resetMessages()),
        dispatch(resetAccounts()),
        dispatch(resetReports()),
        dispatch(resetReportsPages()),
        dispatch(resetSubscriptionData()),
        dispatch(resetAccount()),
        dispatch(resetCreateReport()),
        dispatch(resetReportPageTemplate()),
        dispatch(resetReportPDF()),
        dispatch(resetUpdateReportForm()),
    ]);
};

/**
 * RELOGIN ACTION HANDLER
 * @type {function}
 */
export const reLogIn = (body = {}) => async (dispatch) => {
    resetLocalCredentials();
    await dispatch(login(body));
    await Promise.all([
        dispatch(resetMessages()),
        dispatch(resetAccounts()),
        dispatch(resetReports()),
        dispatch(resetReportsPages()),
        dispatch(resetSubscriptionData()),
        dispatch(resetCreateReport()),
        dispatch(resetReportPageTemplate()),
        dispatch(resetReportPDF()),
        dispatch(resetUpdateReportForm()),
    ]);
    // await dispatch(getMessages());
};

/**
 * auth Reducer
 */
export default baseReducer([login, logout, get_account_data], authInitialState, {
    [login.fulfilled]: (state, action) => {
        if (action.payload.isAxiosError || action.payload.data === undefined) {
            return {
                ...state,
                ...rejectedState(action.payload?.response?.data?.detail),
            };
        }
        if (action.payload.data?.token?.access) {
            localStorage.setItem(LOCAL_STORAGE.TOKEN_ACCESS, action.payload.data.token.access);
            localStorage.setItem(LOCAL_STORAGE.TOKEN_REFRESH, action.payload.data.token.refresh);
            axios.defaults.headers.common = {
                ...axios.defaults.headers.common,
                [HEADERS.AUTHORIZATION]: `Bearer ${localStorage.getItem(LOCAL_STORAGE.TOKEN_ACCESS)}`,
            };
        }
        return {
            ...state,
            ...fulfilledState,
            is_signed_in: true,
            data: {
                ...state.data,
                ...action.payload.data,
            },
        };
    },
    [get_account_data.fulfilled]: (state, action) => {
        return action.payload.isAxiosError || action.payload.data === undefined
            ? { ...state, ...rejectedState() }
            : {
                  ...state,
                  ...fulfilledState,
                  is_signed_in: true,
                  data: {
                      ...state.data,
                      ...action.payload.data,
                  },
              };
    },
    [logout.fulfilled]: (state, action) => {
        if (action.payload.isAxiosError || action.payload.data === undefined) {
            return {
                ...state,
                ...rejectedState(),
            };
        }
        resetLocalCredentials();
        return {
            ...state,
            ...fulfilledState,
            is_signed_in: false,
            is_logouting_right_now: false,
            data: {
                ...state.data,
                ...action.payload.data,
            },
        };
    },
    [logout.pending]: (state) => {
        return {
            ...state,
            ...pendingState,
            is_logouting_right_now: true,
        };
    },
    [create_feedback.fulfilled]: (state, action) => {
        if (action.payload.isAxiosError || action.payload.data === undefined) {
            return {
                ...state,
            };
        }
        return {
            ...state,
            data: {
                ...state.data,
                company_website: action.payload.data.company_website,
                phone: action.payload.data.phone,
            },
        };
    },
});
