/** @module reducers/base */

import { createReducer } from '@reduxjs/toolkit';
import { createAction } from 'redux-promise-middleware-actions';
import { XHR_STEPS, LOCAL_STORAGE } from 'src/config/constants';

/** ************************************************ REDUCERS */

/**
 * BASE REDUCER
 * @type {function}
 */
export const baseReducer = (actionMethods, initialState = baseInitialState, cases = {}) => {
    if (!Array.isArray(actionMethods)) actionMethods = [actionMethods];
    let reducers = {};
    const reducers_list = actionMethods.map((actionMethod) => ({
        [actionMethod.fulfilled]: (state, action) => {
            if (action.payload.isAxiosError) {
                return {
                    ...state,
                    ...rejectedState(),
                    data: {
                        ...state.data,
                        ...action?.payload?.response?.data,
                    },
                };
            }
            return {
                ...state,
                ...fulfilledState,
                data: action.payload.data,
            };
        },
        [actionMethod.pending]: (state) => ({
            ...state,
            ...pendingState,
        }),
        [actionMethod.rejected]: (state) => ({
            ...state,
            ...rejectedState(),
        }),
        [XHR_STEPS.RESET(actionMethod.pending.toString().replace('_PENDING', ''))]: (state, action) => ({
            ...action.payload,
        }),
    }));
    reducers_list.forEach((reducer) => {
        reducers = {
            ...reducers,
            ...reducer,
        };
    });
    return createReducer(initialState, {
        ...reducers,
        ...cases,
    });
};

/** ************************************************ STATES */

/** @module reducers/base/states */
/**
 * BASE ERROR OBJECT
 * @type {object}
 */
const BASE_ERROR = {};

/**
 * BASE INITIAL STATE
 * @type {object}
 */
export const baseInitialState = {
    data: [],
    is_loading: false,
    is_error: false,
    error: Object.create(BASE_ERROR),
    is_fetched: false,
};
/**
 * BASE INITIAL STATE with data object
 * @type {object}
 */
export const baseInitialStateDataObject = {
    ...baseInitialState,
    data: {},
};

/**
 * PENDING STATE
 * @type {object}
 */
export const pendingState = {
    is_loading: true,
    is_error: false,
    error: Object.create(BASE_ERROR),
};

/**
 * REJECTED STATE
 * @type {function}
 */
export const rejectedState = (error = {}) => {
    return {
        is_loading: false,
        is_error: true,
        error,
    };
};

/**
 * FULFILLED STATE
 * @type {object}
 */
export const fulfilledState = {
    is_loading: false,
    is_error: false,
    error: Object.create(BASE_ERROR),
    is_fetched: true,
};

/** ************************************************ ACTION HANDLERS */

/** @module reducers/base/action_handlers */

/**
 * BASE ACTION HANDLER (ONCE EXECUTING)
 * @type {function}
 */
export const actionHandlerOnce = (reducer_name, actionMethod, checkToken = false) => (body = {}) => (
    dispatch,
    getState,
) => {
    const { force = false, ...args } = body;
    const {
        [reducer_name]: { is_fetched, is_loading },
        auth: { is_signed_in, is_logouting_right_now },
    } = getState();

    if (
        ((!is_fetched && !is_loading) || (force && !is_loading)) &&
        ((checkToken === true &&
            localStorage.getItem(LOCAL_STORAGE.TOKEN_ACCESS) &&
            is_logouting_right_now === false) ||
            checkToken === false)
    ) {
        // If force you can clear old requests (TODO)
        return dispatch(actionMethod(args));
    }
    if (checkToken === true && !localStorage.getItem(LOCAL_STORAGE.TOKEN_ACCESS) && is_signed_in) {
        window.location.reload();
    }
    return dispatch(action_do_nothing(actionMethod));
};

/**
 * BASE ACTION HANDLER
 * @type {function}
 */
export const actionHandler = (actionMethod, checkToken = false) => (body = {}) => (dispatch, getState) => {
    const {
        auth: { is_signed_in, is_logouting_right_now },
    } = getState();
    if (
        (checkToken === true && localStorage.getItem(LOCAL_STORAGE.TOKEN_ACCESS) && is_logouting_right_now === false) ||
        checkToken === false
    ) {
        return dispatch(actionMethod(body));
    }
    if (checkToken === true && !localStorage.getItem(LOCAL_STORAGE.TOKEN_ACCESS) && is_signed_in) {
        window.location.reload();
    }
    return dispatch(action_do_nothing(actionMethod));
};

/**
 * BASE ACTION HANDLER FOR RESET
 * @type {function}
 */
export const actionHandlerReset = (actionMethod, initialState = baseInitialState) => () => actionMethod(initialState);

/**
 * STUB ACTION HANDLER
 * @type {object}
 */
export const action_do_nothing = (actionMethod) =>
    createAction(XHR_STEPS.DO_NOTHING(actionMethod.pending.toString().replace('_PENDING', '')));

/** ************************************************ ACTIONS */

/** @module reducers/base/actions */

/**
 * BASE ACTION FOR RESET
 * @type {function}
 */
export const createActionReset = (action_type) => createAction(XHR_STEPS.RESET(action_type), (data) => data);

/** ************************************************ MOCKING */

/** @module reducers/base/mocking */

/**
 * MOCK ACTION
 * @type {function}
 */
export const mock = (data, time = 0) => (inner_data = {}) =>
    new Promise((resolve) => {
        setTimeout(() => resolve({ data: Array.isArray(data) ? data : { ...data, ...inner_data } }), time);
    });
