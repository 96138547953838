/** @module config/axios */

import axios from 'axios';
import { HEADERS, LOCAL_STORAGE } from 'src/config/constants';

/**
 * TIMEOUT of requests
 * @constant
 * @type {number}
 */
const TIMEOUT = 3 * 60 * 1000;

let MEDIAURLINNER = '';
axios.defaults.timeout = TIMEOUT;
axios.defaults.headers.common = {
    ...axios.defaults.headers.common,
    [HEADERS.CACHE_CONTROL]: `no-cache`,
};
if (window.location.hostname === 'localhost') {
    axios.defaults.baseURL = 'http://localhost:9000/api';
    MEDIAURLINNER = 'http://localhost:9000';
} else if (window.location.hostname === '192.168.88.26') {
    axios.defaults.baseURL = 'http://192.168.88.26:9000/api';
    MEDIAURLINNER = 'http://192.168.88.26:9000';
} else {
    axios.defaults.baseURL = `${window.location.protocol}//${window.location.host}/api`;
    MEDIAURLINNER = `${window.location.protocol}//${window.location.host}`;
}
if (localStorage.getItem(LOCAL_STORAGE.TOKEN_ACCESS)) {
    axios.defaults.headers.common = {
        ...axios.defaults.headers.common,
        [HEADERS.AUTHORIZATION]: `Bearer ${localStorage.getItem(LOCAL_STORAGE.TOKEN_ACCESS)}`,
    };
}
if (localStorage.getItem(LOCAL_STORAGE.SELECTED_ACCOUNT_ID)) {
    axios.defaults.headers.common = {
        ...axios.defaults.headers.common,
        [HEADERS.ACCOUNT_ID]: localStorage.getItem(LOCAL_STORAGE.SELECTED_ACCOUNT_ID),
    };
}

/**
 * urls of mediafiles
 * @constant
 * @type {string}
 */
export const MEDIAURL = MEDIAURLINNER;

const setupAxiosInterceptors = () => {
    const onRequestSuccess = (config) => {
        return config;
    };
    const onResponseSuccess = (response) => {
        return response;
    };
    const onResponseError = (error) => {
        const status = error.status || error.response?.status;
        if (status === 401) {
            localStorage.removeItem(LOCAL_STORAGE.TOKEN_ACCESS);
            localStorage.removeItem(LOCAL_STORAGE.TOKEN_REFRESH);
            localStorage.removeItem(LOCAL_STORAGE.SELECTED_ACCOUNT_ID);
            localStorage.removeItem(LOCAL_STORAGE.SELECTED_ACCOUNT_NAME);
            window.location.href = '/';
        }
        return error;
    };
    axios.interceptors.request.use(onRequestSuccess);
    axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
