/** @module reducers/lending */

import { REDUCERS_NAMES } from 'src/config/constants';
import { baseReducer, actionHandlerOnce } from 'src/reducers/base';
import {
    get_solutions,
    get_faqs,
    get_legal_notice,
    get_offices,
    get_tariffs,
    get_privacy_policy,
} from 'src/reducers/lending/actions';

/**
 * SOLUTION ACTION HANDLER
 * @type {function}
 */
export const getSolutions = actionHandlerOnce(REDUCERS_NAMES.SOLUTIONS, get_solutions);

/**
 * FAQs ACTION HANDLER
 * @type {function}
 */
export const getFAQs = actionHandlerOnce(REDUCERS_NAMES.FAQS, get_faqs);

/**
 * OFFERS ACTION HANDLER
 * @type {function}
 */
 export const getOFFERs = actionHandlerOnce(REDUCERS_NAMES.FAQS, get_faqs);//()=>{}//actionHandlerOnce(REDUCERS_NAMES.OFFERS, get_OFFERs);
/**
 * LEGAL NOTICE ACTION HANDLER
 * @type {function}
 */

/**
 * OFFERS ACTION HANDLER
 * @type {function}
 */
 export const getTrainings = actionHandlerOnce(REDUCERS_NAMES.FAQS, get_faqs);//()=>{}//actionHandlerOnce(REDUCERS_NAMES.OFFERS, get_OFFERs);

export const getLegalNotice = actionHandlerOnce(REDUCERS_NAMES.LEGAL_NOTICE, get_legal_notice);

/**
 * PRIVACY POLICY ACTION HANDLER
 * @type {function}
 */
export const getPrivacyPolicy = actionHandlerOnce(REDUCERS_NAMES.PRIVACY_POLICY, get_privacy_policy);

/**
 * OFFICES ACTION HANDLER
 * @type {function}
 */
export const getOffices = actionHandlerOnce(REDUCERS_NAMES.OFFICES, get_offices);

/**
 * TARIFFS ACTION HANDLER
 * @type {function}
 */
export const getTariffs = actionHandlerOnce(REDUCERS_NAMES.TARIFFS, get_tariffs);

/**
 * Solutions Reducer
 */
export const solutions = baseReducer(get_solutions);

/**
 * FAQs Reducer
 */
export const faqs = baseReducer(get_faqs);

/**
 * Legal Notice Reducer
 */
export const legal_notice = baseReducer(get_legal_notice);

/**
 * Privacy Policy Reducer
 */
export const privacy_policy = baseReducer(get_privacy_policy);

/**
 * Offices Reducer
 */
export const offices = baseReducer(get_offices);

/**
 * Tariffs Reducer
 */
export const tariffs = baseReducer(get_tariffs);
