import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { APPS_PATHS, STATIC_PAGES_SLUGS } from 'src/config/constants';
import { history } from 'src/config/store';
import { logout_btn_text } from 'src/config/translation';
import Avatar from 'src/images/avatar.png';
import AuditBtn from 'src/components/layouts/base/btns/AuditBtn'
import {
    account_panel,
    link_yellow,
    account_panel_nav,
    account_panel_menu,
    account_panel_menu_list,
    account_panel_menu_item,
    account_panel_menu_wrapper,
    account_panel_menu_blanc,
} from 'src/styles/Common.module.scss';
import { createPath } from 'src/utils/pathManager';
import useDeviceDetect from 'src/utils/useDeviceDetect';

const redirectOnLogOut = (callback, lang) => () => {
    callback();
    history.push(createPath([lang, APPS_PATHS.ACCOUNT[lang]]));
};

export default React.memo(
    ({
        lang,
        auth: {
            is_signed_in,
            data: { name, icon },
        },
        logOut,
    }) => {
        const [is_menu_opened, setMenuOpened] = useState(false);
        const isMobile = useDeviceDetect();

        const openMenu = () => setMenuOpened(true);
        const closeMenu = () => setMenuOpened(false);

        return (
            <div className={account_panel} onMouseEnter={openMenu} onMouseLeave={closeMenu} onTouchStart={openMenu}>
                {!is_signed_in ? (
                    <>
                        <AuditBtn lang={lang} />
                    </>
                ) : (
                    <>
                        <AuditBtn lang={lang} hidden />
                        {!isMobile ? (
                            <Link className={link_yellow} to={createPath([lang, APPS_PATHS.ACCOUNT[lang]])}>
                                {name}
                            </Link>
                        ) : (
                            <div className={link_yellow}>{name}</div>
                        )}
                        <div className={account_panel_nav}>
                            <img src={icon || Avatar} alt='avatar' loading='lazy' width={40} height={40} />
                            <div
                                className={account_panel_menu_wrapper}
                                style={{ display: is_menu_opened ? 'block' : 'none' }}>
                                <div className={account_panel_menu_blanc} />
                                <div className={account_panel_menu}>
                                    <div className={account_panel_menu_list}>
                                        <Link
                                            onClick={closeMenu}
                                            className={account_panel_menu_item}
                                            to={createPath([
                                                lang,
                                                APPS_PATHS.ACCOUNT[lang],
                                                STATIC_PAGES_SLUGS.CREATE_REPORT[lang],
                                            ])}>
                                            {lang in STATIC_PAGES_SLUGS.CREATE_REPORT &&
                                                STATIC_PAGES_SLUGS.CREATE_REPORT[lang].split('-').join(' ')}
                                        </Link>
                                        <Link
                                            onClick={closeMenu}
                                            className={account_panel_menu_item}
                                            to={createPath([lang, APPS_PATHS.ACCOUNT[lang]])}>
                                            {lang in STATIC_PAGES_SLUGS.MY_DASHBOARD &&
                                                STATIC_PAGES_SLUGS.MY_DASHBOARD[lang].split('-').join(' ')}
                                        </Link>
                                        <Link
                                            onClick={closeMenu}
                                            className={account_panel_menu_item}
                                            to={createPath([
                                                lang,
                                                APPS_PATHS.ACCOUNT[lang],
                                                STATIC_PAGES_SLUGS.SUBSCRIPTION[lang],
                                            ])}>
                                            {lang in STATIC_PAGES_SLUGS.SUBSCRIPTION &&
                                                STATIC_PAGES_SLUGS.SUBSCRIPTION[lang].split('-').join(' ')}
                                        </Link>
                                        <button
                                            className={account_panel_menu_item}
                                            onClick={redirectOnLogOut(logOut, lang)}>
                                            {lang in logout_btn_text && logout_btn_text[lang]}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    },
);
