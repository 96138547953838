import React, { PureComponent, lazy, Suspense } from 'react';

import produce from 'immer';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import LoadingIndicator, { LOADING_INDICATOR_POSITION } from 'src/components/layouts/base/LoadingIndicator';
import BasePage from 'src/components/layouts/BasePage';
import FixedLoadingIndicator from 'src/components/layouts/FixedLoadingIndicator';
import ZenDesk from 'src/components/layouts/ZenDesk';
import Drift from 'src/components/layouts/Drift';
import AuthAlertModal from 'src/components/modals/AuthAlertModal';
// import { ErrorPage } from 'src/components/sections';
import { APPS_PATHS } from 'src/config/constants';
import { history } from 'src/config/store';
import { getAccountData } from 'src/reducers/account/auth';
import { refreshToken } from 'src/reducers/account/reducers';
import { getMain } from 'src/reducers/lending/main';
import { createPath } from 'src/utils/pathManager';
import goTop from 'src/utils/goTop';
import ButtonUp from "src/components/layouts/base/ui/buttonUp";

import LoginRedirect from './components/layouts/LoginRedirect';

const AppLending = lazy(() => import('./AppLending'));
const AppAccount = lazy(() => import('./AppAccount'));
const ErrorPage = lazy(() => import('src/components/sections/common/ErrorPage/ErrorPage'));

class App extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            scroll: 0
        }
        this.checkScroll = this.checkScroll.bind(this);
    }

    componentDidMount() {
        const { getMain, getAccountData, refreshToken } = this.props;
        this.checkScroll();
        refreshToken();
        getAccountData();
        this.checkEndSlash();
        getMain();
        goTop();
    }

    checkScroll = () => {
        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 200 && this.state.scroll === 0) {
                this.setState({
                    scroll: window.pageYOffset
                })
            } else if (this.state.scroll !== 0 && window.pageYOffset < 200) {
                this.setState({
                    scroll: 0
                })
            }
        });
    }

    checkEndSlash = () => {
        // url must end with /
        if (!window.location.pathname.endsWith('/')) {
            history.push(`${window.location.pathname}/`);
        }
    };

    render() {
        const {
            is_error,
            match: {
                url,
                params: { lang_code },
            },
        } = this.props;

        return (
            <BasePage>
                <Drift />
                {this.state.scroll > 0 ? < ButtonUp /> : ''}
                <LoginRedirect />
                <FixedLoadingIndicator />
                <AuthAlertModal />
                <Switch>
                    {
                        <>
                            {is_error ? (
                                <Route path='*' component={ErrorPage} />
                            ) : (
                                <>
                                    <Suspense
                                        fallback={<LoadingIndicator position={LOADING_INDICATOR_POSITION.ABSOLUTE} />}>
                                        <Switch>
                                            {/** Account App */}
                                            <Route
                                                path={createPath([url, APPS_PATHS.ACCOUNT[lang_code]])}
                                                render={(props) => {
                                                    const new_props = produce(props, (draft_props) => {
                                                        draft_props.match.params.lang_code = lang_code;
                                                    });
                                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                                    return <AppAccount {...new_props} />;
                                                }}
                                            />
                                            {/** Lending App */}
                                            <Route
                                                path={createPath([url, APPS_PATHS.LENDING[lang_code]])}
                                                render={(props) => {
                                                    const new_props = produce(props, (draft_props) => {
                                                        draft_props.match.params.lang_code = lang_code;
                                                    });
                                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                                    return <AppLending {...new_props} />;
                                                }}
                                            />
                                        </Switch>
                                    </Suspense>
                                </>
                            )}
                        </>
                    }
                </Switch>
            </BasePage>
        );
    }
}

export default connect(
    ({ main: { is_error } }) => ({
        is_error,
    }),
    {
        getMain,
        getAccountData,
        refreshToken,
    },
)(App);
