import React from "react";
import animateScrollTo from 'animated-scroll-to';
import { btn_up } from "src/styles/Btns.module.scss";
import arrow from "src/images/arrow-up.svg"

const ButtonUp = () => {

    return (
        <button className={btn_up} onClick={() => animateScrollTo(0)}>
            <img src={`${arrow}`} style={{ width: "25px", height: "25px" }} />
        </button>
    )
}

export default ButtonUp;
