import React, { useEffect, useContext, useMemo } from 'react';

import { connect } from 'react-redux';
import { APPS_PATHS, STATIC_PAGES_SLUGS, LOCAL_STORAGE } from 'src/config/constants';
import { LangContext } from 'src/config/contexts';
import { history } from 'src/config/store';
import { createPath } from 'src/utils/pathManager';

const LoginRedirect = React.memo(
    ({
        auth: {
            is_fetched,
            is_logouting_right_now,
            data: { has_reports },
        },
    }) => {
        const lang = useContext(LangContext);

        // eslint-disable-next-line react-hooks/exhaustive-deps
        const is_logged = useMemo(() => localStorage.getItem(LOCAL_STORAGE.TOKEN_ACCESS) !== null, [
            is_logouting_right_now,
        ]);

        useEffect(() => {
            if (is_fetched && !is_logged) {
                if (has_reports) {
                    history.push(createPath([lang, APPS_PATHS.ACCOUNT[lang]]));
                } else {
                    history.push(createPath([lang, APPS_PATHS.ACCOUNT[lang], STATIC_PAGES_SLUGS.CREATE_REPORT[lang]]));
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [is_fetched]);
        return <></>;
    },
);

export default connect(
    ({ auth }) => ({
        auth,
    }),
    {},
)(LoginRedirect);
